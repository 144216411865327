@font-face {
  font-family: "Digi";
  font-style: normal;
  font-weight: 400;
  src: local("Digi"), url(../font/ds-digi.woff2) format("woff2");
  font-display: block;
}

body {
  background: #000;
  margin: 0;
}

p {
  color: #fff;
}

.light {
  background-image: linear-gradient(
    0.25turn,
    rgb(179, 255, 0),
    15%,
    rgba(255, 255, 255, 0)
  );
}

#root {
  margin: 50px;
}

#outerFace {
  margin: 0 auto;
  border: 5px solid #0d69ae;
  border-radius: 10px;
  width: 300px;
}

h1 {
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 10px 0 10px 40px;
}

.model {
  color: #bbac7f;
  font-family: sans-serif;
  font-weight: bold;
  font-style: italic;
}
.feature {
  color: #bbac7f;
  font-family: sans-serif;
  float: right;
  padding-right: 15px;
}

.make-model {
  border-bottom: 5px solid #0d69ae;
}

#lcdFace {
  font-family: Digi;
  text-align: right;
  background-color: #d3dadc;
  border: 2px solid #fff;
  border-radius: 10px;
  width: 265px;
  margin: 0 auto;
}

.casio p {
  font-family: Digi;
  font-size: 82px;
  height: 125px;
  line-height: 150px;
  margin: 0;
  color: #000;
  margin: 0;
}

.black-border {
  border: 2px solid #000;
  padding: 10px;
  border-radius: 10px;
}

.day-date {
  text-align: left;
}

.date,
.day {
  font-size: 42px;
  display: inline-block;
}

.twenty-four-am-pm {
  display: inline-block;
  width: 42px;
}

.twenty-four,
.am-pm {
  font-size: 25px;
  display: inline-block;
}

.date {
  float: right;
}

.day {
  margin-left: 70px;
}

.twenty-four {
  text-align: left;
}

.seconds {
  display: inline-block;
  min-width: 56px;
  font-size: 56px;
  margin-left: 5px;
  /* prevent time from shifting on smaller glifs  */
  text-align: left;
}

.time {
  font-size: 82px;
}

.water-resist {
  text-align: center;
  font-family: sans-serif;
  font-size: 12px;
  border-top: 3px solid #0d69ae;
}

.wr {
  color: #ce4441; /* #942f2d; original color does not meet AA 4.5 contrast ratio */
  border: 2px solid #0d69ae;
  border-radius: 10px;
  padding: 1px 8px;
  font-size: 16px;
  font-style: italic;
}

@media only screen and (max-width: 400px) {
  #root {
    margin: 5px;
  }

  #outerFace {
    width: 97%;
  }

  #lcdFace {
    width: 90%;
  }
}

footer {
  width: 100%;
  text-align: center;
  margin: 10px auto;
  color: #fff;
}

footer a {
  color: #fff;
}

.hide {
  display: none;
}
